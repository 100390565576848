import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import css from './BucketRelaxationMessage.APP_TARGET.scss';
import { EXTENDED_LOCATION, LISTING } from 'Constants/tracking';
import { ITEM_SEARCH_RELAXATION_TYPE_BUCKET } from 'Constants/items';
import withTrack from 'HOCs/withTrack/withTrack';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

export class BucketRelaxationMessage extends React.PureComponent {
    static propTypes = {
        section: PropTypes.object.isRequired,
        selectedLocationName: PropTypes.string.isRequired,
        isCurrentGPSLocation: PropTypes.bool.isRequired,
        intl: PropTypes.shape({
            formatMessage: PropTypes.func.isRequired
        }).isRequired,
        track: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { section, track } = this.props;

        track(EXTENDED_LOCATION, {
            select_from: LISTING,
            extended_distance: section.distance,
            extended_distance_criterion: section.criterion,
            extended_relaxation_type: ITEM_SEARCH_RELAXATION_TYPE_BUCKET
        });
    }

    render() {
        const { section, selectedLocationName, isCurrentGPSLocation, intl } = this.props;

        const distance = `${section.distance} ${section.distance > 1 ? 'kms' : 'km'}`;
        const resultsInFirstSection = section.offset > 0;

        let startMessageId = 'relaxationBucketLessThan';
        let endMessageId = isCurrentGPSLocation ? 'fromYou' : 'fromLocation';

        if (section.criterion === 'greater_than') {
            startMessageId = 'relaxationBucketGreaterThan';
            endMessageId = isCurrentGPSLocation ? 'awayFromYou' : 'awayFromLocation';
        }

        const startMessage = intl.formatMessage({ id: startMessageId });
        const endMessage = intl.formatMessage({ id: endMessageId }, { location: selectedLocationName });

        return (
            <div className={ classNames(css.message, { [css.messageWithMarginTop]: resultsInFirstSection }) }>
                <span id={ startMessageId }>{ startMessage }</span>&nbsp;<b>{ distance }</b>&nbsp;<span id={ endMessageId }>{ endMessage }</span>
            </div>
        );
    }
}

export default compose(
    injectIntl,
    withTrack
)(BucketRelaxationMessage);
