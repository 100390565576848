import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import css from './ItemView.scss';
import cssLamudi from 'Components/LamudiBranding/LamudiBranding.scss';
import Placeholder from './Placeholder';
import ItemCard from './ItemCard';
import ItemPostPlaceholder from './ItemPostPlaceholder';
import SectionTitle from './Sections/SectionTitle';
import LamudiBranding from 'Components/LamudiBranding/LamudiBranding';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { ITEM_SOURCE } from 'Constants/items';
import { noop } from 'Helpers/function';
import { showLamudiBranding } from 'Helpers/lamudiHelper';
import { isMobile } from 'Helpers/devices';
import { LAMUDI_LISTING_BANNER_MOBILE } from 'Constants/imageUrls';
import AdvertisingComponent from 'Components/AdvertisingComponent/AdvertisingComponent';
import { middleAdContainerPattern, middleAdContainerPattern2 } from '../../../pages/Listing/utils';
import { CONFIG } from './constants';
import { GRID } from 'Constants/constants';
import { RESULTS_PAGE } from 'Constants/baxter';
import { CONFIG as listConfig } from 'Constants/config';
import CarsPlaceholder from './CarsPlaceholder';
import { isAutosAvailable } from './helpers';

const itemPostPlaceholderPosition = (config, placeholderOrigin) => {
    const { enabled, home, listing } = config.get(CONFIG.ITEM_POST_PLACEHOLDER);

    if (enabled && placeholderOrigin) {
        return placeholderOrigin === 'home'
            ? home.itemsBefore
            : listing.itemsBefore;
    }
    return null;
};

export const ItemViewListing = ({
    items, expectedItems, isFetching, showStatistics, similarAdsData,
    type, enabledRealImpressions, onViewItem, isAdSuggested,
    visualizationType, fourColumns, isClassified, itemPostPlaceholderOrigin,
    sectionsItems, listingType, getListingDescription, showInspectionTag,
    filters, placeholderImagesStyle, marketConfig, onItemClick, source, loadMoreBtn, extraTrackAttr, categoryID, categoryTree, userType, locationId }) => {
    const loadingItems = Array.from(Array(expectedItems).keys()).map(
        index => <Placeholder key={ `placeholder_${index}` } visualizationType={ visualizationType } placeholderImagesStyle={ placeholderImagesStyle } />
    );
    const placeholderPosition = itemPostPlaceholderPosition(
        marketConfig,
        itemPostPlaceholderOrigin
    );
    const sectionsOffset = sectionsItems && sectionsItems.map(({ offset }) => offset);

    const showMiddleAdHome = marketConfig.get(CONFIG.MIDDLE_AD_HOME);
    const showMiddleAdCategory = marketConfig.get(CONFIG.MIDDLE_AD_CATEGORY);
    const middleAdPattern2 = marketConfig.get(CONFIG.MIDDLE_AD_PATTERN2);

    const itemsList = items.map((item, itemIndex) => {
        const hasSection = sectionsOffset && sectionsOffset.includes(itemIndex);

        return (
            <React.Fragment key={ `${item.id}_${itemIndex}` }>
                {hasSection
                    && <SectionTitle
                        section={ sectionsItems && sectionsItems.find(section => section.offset === itemIndex) }
                    />}
                <ItemCard
                    key={ item.id }
                    item={ item }
                    userType={ userType }
                    friendsInCommon={ false }
                    type={ type }
                    enabledRealImpressions={ enabledRealImpressions }
                    onView={ onViewItem }
                    visualizationType={ visualizationType }
                    showStatistics={ showStatistics }
                    listingType={ listingType }
                    getListingDescription={ getListingDescription }
                    filters={ filters }
                    onItemClick={ onItemClick }
                    source={ source }
                    isAdSuggested={ isAdSuggested }
                    chosenOption={ itemIndex }
                    showInspectionTag={ showInspectionTag }
                    extraTrackAttr={ extraTrackAttr }
                    isClassified={ isClassified }
                    sellerMembershiptype={ item.seller_membershiptype }
                />
            </React.Fragment>
        );
    });
    const getItemPlaceholder = () => {
        if (isAutosAvailable(marketConfig, items, locationId.toString()) && itemPostPlaceholderOrigin !== listConfig.HOME) {
            return (<CarsPlaceholder
                key={ listConfig.CARS_PLACEHOLDER }
                placeholderPosition={ placeholderPosition }
                items={ items }
            />);
        }
        return (<ItemPostPlaceholder
            key="postPlaceholder"
            itemPostPlaceholderOrigin={ itemPostPlaceholderOrigin }
            visualizationType={ visualizationType }
        />);
    };

    if (placeholderPosition && items.length && !similarAdsData?.length) {
        const ItemPlaceholder
            = getItemPlaceholder();

        itemsList.splice(placeholderPosition, 0, ItemPlaceholder);
    }

    const isMiddleAdPage = (source === ITEM_SOURCE.HOME && showMiddleAdHome) || (showMiddleAdCategory && source === ITEM_SOURCE.BROWSE || source === ITEM_SOURCE.SEARCH);

    if (isMobile && isMiddleAdPage) {
        let baxterPageName;

        if (source === ITEM_SOURCE.HOME) {
            baxterPageName = ITEM_SOURCE.HOME;
        }
        else if (source === ITEM_SOURCE.BROWSE || source === ITEM_SOURCE.SEARCH) {
            baxterPageName = RESULTS_PAGE;
        }

        const renderBanners = slot => {
            return (
                <li className={ css.middleAdContainer } key={ slot } >
                    <AdvertisingComponent
                        slot={ slot }
                        page={ baxterPageName }
                        category={ categoryTree }
                    />
                </li >
            );
        };

        const isMiddleAdPattern2 = middleAdPattern2 && (source === ITEM_SOURCE.BROWSE || source === ITEM_SOURCE.SEARCH);
        const adIndices = isMiddleAdPattern2 ? middleAdContainerPattern2(itemsList.length) : middleAdContainerPattern(itemsList.length);

        for (let index = 0; index < adIndices.length; index++) {
            itemsList.splice(adIndices[index].index, 0, renderBanners(`middle${adIndices[index].adsCounter}`));
        }
    }

    if (items.length && isMobile && showLamudiBranding(marketConfig, categoryID)) {
        const { mobileListingBannerIdx, listingBannerRedirectionUrl } = marketConfig.get(CONFIG.LAMUDI_BRANDING) || {};
        const lamudiBanner = <LamudiBranding assetUrl={ LAMUDI_LISTING_BANNER_MOBILE } redirectionUrl={ listingBannerRedirectionUrl } cssClass={ cssLamudi.listingBanner } />;

        itemsList.splice(mobileListingBannerIdx, 0, lamudiBanner);
    }

    if (isFetching) {
        itemsList.push(loadingItems);
    }

    const columns = (visualizationType === GRID && fourColumns)
        ? css[`${visualizationType}sSideByFour`]
        : css[`${visualizationType}sSide`];

    return (
        <ul className={ classNames(css[`${visualizationType}Items`], columns, { [css.fetchingList]: isFetching }) } data-aut-id="itemsList">
            {itemsList}
            <li className={ css.loadMoreBtnContainer }>{loadMoreBtn}</li>
        </ul>
    );
};

ItemViewListing.propTypes = {
    items: PropTypes.array.isRequired,
    similarAdsData: PropTypes.array,
    type: PropTypes.string,
    onViewItem: PropTypes.func,
    isFetching: PropTypes.bool,
    fourColumns: PropTypes.bool,
    categoryTree: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isClassified: PropTypes.bool,
    showStatistics: PropTypes.bool,
    expectedItems: PropTypes.number,
    enabledRealImpressions: PropTypes.bool,
    itemPostPlaceholderOrigin: PropTypes.string,
    visualizationType: PropTypes.oneOf(['grid', 'big', 'list']),
    sectionsItems: PropTypes.arrayOf(
        PropTypes.shape({
            offset: PropTypes.number,
            location: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                type: PropTypes.string
            })
        })
    ),
    listingType: PropTypes.string,
    getListingDescription: PropTypes.func,
    filters: PropTypes.object,
    placeholderImagesStyle: PropTypes.string,
    marketConfig: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    onItemClick: PropTypes.func,
    isAdSuggested: PropTypes.bool,
    source: PropTypes.string,
    loadMoreBtn: PropTypes.node,
    showInspectionTag: PropTypes.bool,
    extraTrackAttr: PropTypes.object,
    categoryID: PropTypes.string,
    userType: PropTypes.string,
    locationId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};

ItemViewListing.defaultProps = {
    expectedItems: 20,
    fourColumns: false,
    isClassified: false,
    visualizationType: 'grid',
    itemPostPlaceholderOrigin: '',
    listingType: 'items',
    similarAdsData: [],
    onItemClick: noop,
    getListingDescription: noop,
    filters: {},
    placeholderImagesStyle: '',
    isAdSuggested: false,
    source: ITEM_SOURCE.OTHER,
    loadMoreBtn: null,
    showInspectionTag: false,
    extraTrackAttr: {},
    locationId: ''
};

export default compose(withConfig('marketConfig'))(ItemViewListing);
