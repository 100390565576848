import { LISTING_ITEMS_GAP_AD, LISTING_ITEMS_TOTAL_ADS, LISTING_MIDDLE_ADS_EXTRA_ITEMS, LISTING_MIDDLE_ADS_PATTERN_CHANGE_VALUE, LISTING_MIDDLE_ADS_PATTERN_CHANGE_ITEMS, LISTING_ITEMS_TOTAL_ADS_CATEGORY } from '../Posting/constants';

export function middleAdContainerPattern(itemsLength) {
    let i = LISTING_ITEMS_GAP_AD;
    let adsCounter = 1;
    const adIndices = [];

    while (i < (LISTING_ITEMS_TOTAL_ADS * LISTING_ITEMS_GAP_AD + LISTING_ITEMS_TOTAL_ADS + LISTING_MIDDLE_ADS_EXTRA_ITEMS) && i < itemsLength) {
        adIndices.push({ index: i, adsCounter });
        let extraGap = 0;

        if (adsCounter === 2) {
            extraGap = 2;
        }
        else if (adsCounter === LISTING_MIDDLE_ADS_PATTERN_CHANGE_VALUE) {
            extraGap = LISTING_MIDDLE_ADS_PATTERN_CHANGE_ITEMS;
        }
        i += LISTING_ITEMS_GAP_AD + 1 + extraGap;
        adsCounter++;
    }
    return adIndices;
}

export function middleAdContainerPattern2(itemsLength) {
    let i = LISTING_ITEMS_GAP_AD;
    let adsCounter = 1;
    const adIndices = [{ index: 2, adsCounter }];

    adsCounter++;
    i++;

    while (i < (LISTING_ITEMS_TOTAL_ADS_CATEGORY * LISTING_ITEMS_GAP_AD + LISTING_ITEMS_TOTAL_ADS_CATEGORY) && i < itemsLength) {
        adIndices.push({ index: i, adsCounter });
        i += LISTING_ITEMS_GAP_AD + 1;
        adsCounter++;
    }
    return adIndices;
}
